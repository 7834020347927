.elevation {
  position: relative;
  background-color: #212548;
  width: 100%;
  z-index: 98;
}

.loader {
  width: 100%;
  height: 100%;
}

text.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 12px !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 361px) {
  .recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-top {
    transform: translate(8px, -25px) !important;
  }
}
