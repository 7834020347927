.mapboxgl-popup {
  z-index: 999;
}
.mapboxgl-popup-content {
  box-sizing: border-box;
  background-color: white;
  width: 310px;
  padding: 0 !important;
  padding-bottom: 1px;
  cursor: default;
  border-radius: 15px;
}

.mapboxgl-ctrl-group button {
  box-shadow: none;
}

.section-container {
  display: flex;
  position: relative;
  top: 0;
  pointer-events: none;
  gap: 15px;
  z-index: 920;
}

.overlays .section-container { 
  left:0px !important;
}

.button-right-style {
  border-radius: 10px;
  background-color: white;
  border: none;
  height: 30px;
  margin-bottom: 5px;
  pointer-events: auto;
  gap: 5px;
}

button.button-right-style.w-svg {
  gap: 5px;
}

.left-btn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
  border: none;
  margin-left: 25px;
  pointer-events: auto;
  cursor: pointer;
}

.info {
  border-radius: 50%;
  margin-top: 25px;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  pointer-events: auto;
  background-color: white !important;
  color: black !important;
}

.lang {
  border-radius: 5px;
  width: fit-content;
  background-color: white;
  font-size: 1.5em;
  padding: 5px 5px;
  pointer-events: auto;
}

button.info {
  height: 56px;
  width: 56px;
}

button.info.left-btn,
select#language {
  box-shadow: 0px 2px 10px 3px rgb(0 0 0 / 10%);
}

@media screen and (max-width: 897px) {
  .info {
    margin-top: 20px;
    margin-left: 20px;
  }
  .left-btn {
    margin-top: 20px;
    margin-left: 20px;
  }

}
