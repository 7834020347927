.right {
  display: flex !important;
  flex-direction: column;
  position: absolute;
  right: 30px;
  top: 0px;
  width: 72px;
  z-index: 800;
}

.button-right-style {
  border-radius: 10px !important;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 72px;
  margin: 5px 0;
  height: 42px !important;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  color: #212548;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-svg:active, .w-svg:focus, .button-right-style:focus {
  background-color: white;
}

button.button-right-style:last-child {
  font-size: 12px;
}

button.button-right-style:last-child:hover {
  font-size: 12px;
}

.language-selector {
  border-radius: 10px !important;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 72px;
  margin: 5px 0;
  height: 42px !important;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  color: #212548;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-svg {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
}

.svg-icon-style {
  fill: #212548;
}

.button-right-style:hover {
  background-color: #F2F2F2;
}

.button-right-style:active {
  background-color: #F2F2F2;
}

.active {
  border: solid !important;
  border-width: 3px !important;
  border-color: #ffffff;
  background-color: #2c3259 !important;
  color: white;
}

.right:first-child {
  margin-top: 20px;
}

@media screen and (max-width: 897px) {
  .right {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 25px;
    width: 72px;
    z-index: 800;
  }
  .right:first-child {
    margin-top: 20px;
  }

  .button-right-style, .language-selector {
    margin: 5px 0;
  }
}
/* .right:first-child {
  margin-top: 5px;
} */

.section-container { 
  left:0px !important;
}
