.card-more-info {
  display: flex;
  flex-direction: column;
  position:absolute;
  width: 100%;
  height: 80% !important;
  border-radius: 10px;
  text-align: start;
  background-color: white;
  margin-top: 25px;
  left: 25px;
  z-index: 800;
  pointer-events: all;
  overflow: auto;
  scroll-behavior: auto;
  cursor: auto;
  scrollbar-width:inherit !important;
}

.css-3ucjj5-control {
  flex-wrap: nowrap !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #efefef; 
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8; 
  border-radius: 10px;
}


.card-container {
  z-index: 800;
}

.image-more-info {
  background-image: url("https://images.ctfassets.net/hrltx12pl8hq/61DiwECVps74bWazF88Cy9/2cc9411d050b8ca50530cf97b3e51c96/Image_Cover.jpg?fit=fill&w=480&h=270");
  background-size: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 200px !important;
  min-height: 200px !important;
  margin-bottom: 15px;
  pointer-events: auto;
  background-position: center;
}

.text-more-info {
  color: black;
  font-family: sans-serif;
  pointer-events: auto;
}

.heading-more-info {
  line-height: 0.5;
  margin-bottom: 20px;
  pointer-events: auto;
}

.heading-more-info h2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(73, 77, 105, 0.6);
  margin: 0;
  margin-bottom: 20px;
}
.heading-more-info h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #212548;
  margin-bottom: 5px;
}

.p-more-info {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  pointer-events: auto;
  color: black;
}

.icon-text-more-info {
  display: flex;
  width: 100%;
  background-color: white;
  flex-wrap: wrap;
  line-height: 1.8;
  margin-bottom: 10px;
  align-items: stretch;
  justify-content: center;
  pointer-events: auto;
}

.icon-more-info {
  height: 20px;
  display: inline-block;
  margin-bottom: 15px;
}

.type-more-info {
  width: max-content;
  font-weight: 500;
  color: #002c52;
  font-size: 14px !important;
}

.stats-more-info {
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-right: 2%;
  padding-left: 2%;
}
/* .stats-more-info {
  display: inline-block;
  flex-direction: row;
  width: 20%;
  text-align: center;
} */

.informations-more-info {
  padding: 30px;
}

.buttons-more-info {
  padding:0px 30px 30px 30px;
}

.border-more-info {
  border-right: 1px solid #b0b2be;
  border-left: 1px solid #b0b2be;
}

.left-more-info {
  border-left: none;
}

.logo-more-info {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #ffffff;
  color: white;
  width: 100px;
  padding: 5px 2px;
  font-weight: 700;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
}

.level-more-info, .logo-more-info {
  font-size: 14px;
  width: 89px;
  font-weight: 500;
  margin-left: 10px;

}

.level-more-info {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  background-color: #0097d7;
  color: rgb(255, 255, 255);
  width: 100px;
  padding: 5px 2px;
  font-weight: 700;
  text-align: center;
  border-radius: 50px;
  font-size: 14px;
}

.etq-more-info {
  display: inline-flex;
  float: right;
  left: 40%;
  margin-top: 42px;
  margin-right: 30px;
}

.back-more-info,
.back-more-qr {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  float: left;
  margin-top: 30px;
  margin-left: 30px;
  width: 59px;
  height: 59px;
  border-radius: 50%;
  border: none;
  background-color: white;
  pointer-events: auto;
  cursor: pointer;
  background-color: white !important;
  color: black !important;
  padding: 0;
}

.back-more-qr * {
  width: 10px !important;
}

.back-more-info * {
  width: 8px !important;
}

.bck-more-info {
  align-items: center;
  justify-content: center;
}

.buttons-more-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.purple-more-info {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  background-color: #212548;
  color: white;
  border: none;
  border-radius: 5px;
  height: 60px;
  margin-bottom: 10px;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
  gap: 2%;
}

.purple-more-info:hover {
  background-color: #212548;
}

.purple-more-info:active {
  background-color: #212548;
}

.download-more-info {
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-right: 10px;
  stroke: #00ab84;
}

.fwd-more-info {
  display: flex;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 100px;
  border: 1px solid #494d69;
  flex-wrap: wrap;
  align-content: baseline;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  font-size: 14px;
  background-color: white;
  margin-bottom: 5px;
  justify-content: space-between;
  padding: 10px 20px !important;
  font-weight: 500;
  text-decoration: none;
  flex-direction: row;
}

.icne-more-info {
  height: 20px;
  width: 20px;
  margin-left: 20px;
}

div.informations-more-info.text-more-info > div > h1 {
  font-weight: 400;
}

.cls-1 {
  fill: #07ab84 !important;
}

.type-more-info {
  font-size:16px !important;
}

/* MEDIA QUERY */
@media screen and (min-width: 330px) and (max-width: 500px) {
  .card-more-info {
    width: 85%;
    /* height: calc(90%); */
    margin-bottom: 5px;
    margin-top: 20px;
    left: 20px;
    z-index: 800;
  }
  .logo-more-info {
    font-size: 10px;
  }
  .level-more-info {
    font-size: 10px;
  }
  .back-more-info {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 20px;
    color: black !important;
    background-color: white !important;
  }
  .type-more-info {
    font-size: 10px !important;
  }
  .heading-more-info {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .p-more-info {
    font-size: 14px;
    line-height: 20px;
  }
  .purple-more-info {
    font-size: 10px;
    margin-top: 25px;
  }
  .fwd-more-info {
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .informations-more-info {
    padding: 5% 7%;
  }
  .buttons-more-info {
    padding: 0px 11px;
    display: flex;
    justify-content: center;
  }
  .image-more-info {
    margin-bottom: 5px;
  }

  .etq-more-info {
    margin-top: 22px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 500px) {
  .card-more-info {
    width: 90%;
    height: auto;
    z-index: 800;
  }

  .logo-more-info {
    width: 75px;
    font-size: 12px;
  }

  .level-more-info {
    width: 75px;
    font-size: 12px;
  }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
  .card-more-info {
    width: 80%;
    z-index: 800;
    margin-top: 20px;
    margin-left: 20px;
    /* height: auto; */
  }
}

@media only screen and ( (max-width: 700px) or ( max-height:700px ) ) {


  .card-more-info {
    max-height: 95% !important;
    width: 90% !important;
    left: 20px;
    overflow:auto;
    z-index: 800;
  }
  .overlays .right {
    z-index: 2;
  }
  
  .buttons-more-info
  {
  padding:0px 20px 20px 20px;
  }
  .informations-more-info {
    padding: 20px;
  }
  .card-more-info {
    left: 20px;  
    margin-top:20px;
  }
  .etq-more-info {
    margin-right: 20px;
    margin-top: 20px;
  }
  .overlays .right {
    right: 20px !important;
  
  }
  .back-more-info {
    margin-top: 20px;
    margin-left:20px;
  }
  .overlays .left-btn
  {
    margin-left:20px;
    margin-top:20px;
  }
  .level-more-info
  {
  margin-left:10px;
  }
  
  .mapboxgl-ctrl.mapboxgl-ctrl-group:first-child {
    margin-top: 0;
  }
  button.btn.first {
    margin-top: 5px;
  }
  
  }

  @media only screen and (max-width: 897px) and (min-width:400px) {

    .bttn-tooltip { 
    font-size:12px !important;
    letter-spacing:0.6px !important;
    }
    .btn-tooltip {
      width: 80px !important;
    }


    .card-more-info div:first
    {
      min-height: 175px;
      background-size: cover;
      background-position: center center;
      margin-top: 20px;
      margin-left: 20px;
    }

    .close {
      margin-right: 15px;
      margin-top: 15px;
    }

    .back-more-info {
      margin-top: 30px;
      margin-left:30px;

    }
    .etq-more-info {
      margin-right: 30px;
      margin-top: 42px;
    }
    .level-more-info, .logo-more-info {
      font-size: 14px;
      width: 89px;
      font-weight: 500;
      margin-left: 10px;

    }
  }


  @media only screen and ( (max-width: 700px) or ( max-height:700px ) ) {

    .mapboxgl-popup-content 
    {
    width:310px;
    }
    .level-more-info
    {
    margin-left: 10px;
    }
    
    }
    
@media screen and (min-width: 701px) and (max-width: 800px) {
  .card-more-info {
    z-index: 800;
    width: 55%;
    /* height: auto; */
  }
}
@media screen and (min-width: 801px) and (max-width: 900px) {
  .card-more-info {
    z-index: 800;
    width: 45%;
    height: auto;
  }
}
@media screen and (min-width: 901px) and (max-width: 1000px) {
  .card-more-info {
    z-index: 800;
    width: 40%;
    height: auto;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1100px) {
  .card-more-info {
    z-index: 800;
    width: 35%;
    height: auto;
  }
}
@media screen and (min-width: 1101px) and (max-width: 1200px) {
  .card-more-info {
    z-index: 800;
    width: 30%;
    height: auto;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1920px) {
  .card-more-info {
    z-index: 800;
    width: 32%;
    height: auto;
  }
}

@media screen and (min-width: 1921px) {
  .card-more-info {
    z-index: 800;
    width: 20%;
    height: auto;
  }
}


@media screen and (min-width:1101px) and (max-width:1200px) {
  .card-more-info {
    z-index: 800;
    height:auto;
    width:35%
  }
 
 }