.container {
  background-color: white;
  padding: 0;
  border-radius: 10px;
}

.iconpoi {
  color: #00ab84;
  width: 40px;
}

.diviconpoi {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  align-content: center;
  width: 25%;
}

/*===========
   ELEMENTS
   ===========*/

.popup-article-style {
  margin: 20px 30px 30px 30px !important;
  /* margin-bottom: 25px; */
  font-family: sans-serif;
}

.title-heading {
  color: #212548;
  font-weight: 500 !important;
  font-size: 18px;
  margin: 0;
}


.subtitle-heading {
  font-weight: 400;
  font-size: 16px;
  color: rgba(73,77,105,.6);
  margin: 0;
}

.heading {
  line-height: 22px;
  color: #212548;
  font-size: 20px;
}

.article-p-style {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
}

/*========
   ICON + BUTTON
   ==========*/

.map {
  height: 27px;
  width: 27px;
  margin-left: 30px;
  margin-top: 18px;
  fill: #00ab84;
}

.element {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 12px;
}

.close {
  float: right;
  position: relative;
  margin-top: 30px;
  margin-right: 30px;
  width: 59px;
  height: 59px;
  border-radius: 50%;
  border: none;
  background-color: white !important;
  color: black !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .close svg {
  margin-left: 0px !important;
  margin: 0px !important;
} */

@media screen and (max-width: 897px) {
  .close {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    margin-right: 20px;
  }

  .popup-article-style {
    padding: 0px 20px 5px 20px;
    margin: 0 !important;
  }

  .subtitle-heading {
    color: rgba(73, 77, 105, 0.6) ;
    font-weight: 500;
  }

  .container {
    max-width: 310px;
  }

  
}
