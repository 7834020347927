.icon-tooltip {
  height: 15px;
  width: 15px;
  stroke: white;
  padding-right: 5px;
}

.border-tooltip {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-content: space-around;
  border: 1px solid #494d69;
  width: fit-content;
  border-radius: 80px;
  background-color: #212548;
  padding: 1% 0.5%;
  bottom: 0;
}

.btn-tooltip {
  background-color: transparent;
  color: white;
  display: flex;
  color: black;
  border: none;
  align-items: center;
  width: 100px;
  padding: 3px 2px;
}

.bttn-tooltip {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.btn-tooltip,
.bttn-tooltip {
  text-align: center;
  justify-content: center;
  gap: 5px;
  z-index: 930;
}

.icon-tool {
  fill: "white" !important;
}

@media screen and (max-width: 768px) {
  /* .btn-tooltip {
    width: 110px !important;
  } */
  .recharts-tooltip-wrapper.recharts-tooltip-wrapper-right.recharts-tooltip-wrapper-top {
    transform: translate(25px, -3px) !important;
  }
}

@media screen and (max-width: 400px) {
  .recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-top {
    transform: translate(25px, -3px) !important;
  }
}

@media screen and (max-width: 897px) {
  .border-tooltip {
    border-width: 0;
  }
  .btn-tooltip {
    width: 80px;
  }
  .bttn-tooltip {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  .bttn-tooltip {
    font-size: 10px;
  }
  .btn-tooltip {
    width: 70px;
  }
}



@media screen and (max-width: 768px) {
  .recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-top {
    /* transform: translate(0px, -30px) !important; */
    transform: translate(25px, -5px);

    width: 100% !important;
  }
}

@media screen and (max-width: 361px) {
  .recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-top {
    transform: translate(8px, -25px) !important;
  }
}
.popup-content {
  z-index: 900 !important;
}

/* .popup-poi-small-screen-content {
  position: absolute;
  top:auto !important;
  bottom: 130px !important;
}

.popup-poi-large-screen-content {
  top: auto !important;
  bottom: 210px !important;
} */
