#guidosHighlight {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: static;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1 {
    line-height: 20px;
  }

  p, a, h3, button, label {
    font-family: "Hellix";
  }

  h1 {
    font-family: "Hellix";
    font-weight: 500;
    font-size: 20px;
  }

  h2 {
    font-family: "Hellix";
    font-size: 18px;
    font-weight: 500;
  }
}
