.mapboxgl-ctrl-group button {
    width: 70px !important;
    height: 40px !important;
}
.mapboxgl-ctrl.mapboxgl-ctrl-group {
    float: right;
    margin-right: 20px;
    width: 70px;
    background-color: rgb(255, 255, 255);
    border: none;
    box-shadow: none;
    border-radius: 5px;
}

button.button-right-style.first {
    margin-top: 20px;
    font-size: 18px;
  }
  


  @media only screen and ( (max-width: 700px) or ( max-height:700px ) ) {


    .card-more-info {
      max-height: 95% !important;
      width: 90% !important;
      margin-top: 20px;
      left: 20px;
      overflow:auto;
      z-index: 800;
    }
    .overlays .right {
      z-index: 2;
    }
    
    .buttons-more-info
    {
    padding:0px 20px 20px 20px;
    }
    .informations-more-info {
      padding: 20px;
    }
    .card-more-info {
      left: 20px;  
      margin-top:20px;
    }
    .etq-more-info {
      margin-right: 20px;
      margin-top: 20px;
    }
    .overlays .right {
      right: 20px !important;
    
    }
    .back-more-info {
      margin-top: 20px;
      margin-left:20px;
    }
    .overlays .left-btn
    {
      margin-left:20px;
      margin-top:20px;
    }
    .level-more-info
    {
    margin-left:10px;
    }
    
    .mapboxgl-ctrl.mapboxgl-ctrl-group:first-child {
      margin-top: 0;
    }
    button.btn.first {
      margin-top: 5px;
    }
    
    }
.mapboxgl-ctrl.mapboxgl-ctrl-group:first-child {
    float: right;
    margin: 5px 0;
}
  
.popup-content {
    z-index: 900 !important;
}

.mapboxgl-popup-content 
{
    width:360px;
}

.mapboxgl-popup {
    z-index: 999;
  }
  .mapboxgl-popup-content {
    box-sizing: border-box;
    background-color: white;
    width: 310px;
    padding: 0 !important;
    padding-bottom: 1px;
    cursor: default;
    border-radius: 15px;
  }
  
  .mapboxgl-ctrl-group button {
    box-shadow: none;
  }

@media screen and (max-width: 897px) {
  .card-more-info {
    margin-top: 0;
  }
}
  